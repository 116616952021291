import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { authApi } from './api/Auth'
import { apiSlice } from './api/apiSlice'

import {
  getLocalStorageValue,
  setLocalStorageValue,
  LOCAL_STORAGE_AUTH_TOKEN_KEY,
  LOCAL_STORAGE_AUTHED_USER_NAME_KEY,
  LOCAL_STORAGE_AUTHED_USER_PROFILE_IMAGE_URL_KEY,
  LOCAL_STORAGE_CURRENT_DIRECTORY_ID_KEY,
  LOCAL_STORAGE_CURRENT_DIRECTORY_NAME_KEY,
} from './localStorage';

export const onAuthChanged = (auth) => {
  setLocalStorageValue(LOCAL_STORAGE_AUTH_TOKEN_KEY, auth.token);
  setLocalStorageValue(LOCAL_STORAGE_AUTHED_USER_NAME_KEY, auth.userName);
  setLocalStorageValue(LOCAL_STORAGE_AUTHED_USER_PROFILE_IMAGE_URL_KEY, auth.userProfileImageURL);
  setLocalStorageValue(LOCAL_STORAGE_CURRENT_DIRECTORY_ID_KEY, auth.currentDirectoryId);
  setLocalStorageValue(LOCAL_STORAGE_CURRENT_DIRECTORY_NAME_KEY, auth.currentDirectoryName);
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: getLocalStorageValue(LOCAL_STORAGE_AUTH_TOKEN_KEY),
    userName: getLocalStorageValue(LOCAL_STORAGE_AUTHED_USER_NAME_KEY),
    userProfileImageURL: getLocalStorageValue(LOCAL_STORAGE_AUTHED_USER_PROFILE_IMAGE_URL_KEY),
    currentDirectoryId: getLocalStorageValue(LOCAL_STORAGE_CURRENT_DIRECTORY_ID_KEY),
    currentDirectoryName: getLocalStorageValue(LOCAL_STORAGE_CURRENT_DIRECTORY_NAME_KEY),
  },
  reducers: {
    setDirectory: (state, action) => {
      state.currentDirectoryId = action.payload.directoryId;
      state.currentDirectoryName = action.payload.directoryName;
    },
    reset: (state, action) => {
      state.token = undefined;
      state.userName = undefined;
      state.userProfileImageURL = undefined;
      state.currentDirectoryId = undefined;
      state.currentDirectoryName = undefined;
    }
  },
  // TODO: Add a refresh credentials flow when the page is first loaded
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(authApi.endpoints.login.matchFulfilled, apiSlice.endpoints.refreshAuth.matchFulfilled),
      (state, { payload }) => {
        if (payload.auth_token !== undefined) {
          state.token = payload.auth_token;
        }
        state.userName = payload.name;
        state.userProfileImageURL = payload.profile_image_url;
      }
    )
  },
})

export default authSlice.reducer
