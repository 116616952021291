import { configureStore } from '@reduxjs/toolkit'

import { apiSlice } from '../features/api/apiSlice'

import ThemeOptions from './ThemeOptions';
import usersReducer from './UsersSlice';
import authReducer from './AuthSlice';
import searchReducer from './search/SearchSlice';

export default configureStore({
  reducer: {
    ThemeOptions: ThemeOptions,
    users: usersReducer,
    auth: authReducer,
    search: searchReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware)
});
