import React from 'react';

import { REACT_APP_POINT_AT_PROD, REACT_APP_LOCAL } from './config'

let LOCAL;
if (REACT_APP_LOCAL) {
  LOCAL = true;
} else {
  LOCAL = false;
}

console.log('>>> ' + REACT_APP_LOCAL + " | " + REACT_APP_POINT_AT_PROD);

let localPort;
let remoteURL;
if (REACT_APP_POINT_AT_PROD) {
  localPort = 8092;
  remoteURL = 'https://api.globalauthority.verifyyou.io';
} else {
  localPort = 8072;
  remoteURL = 'https://dev.api.globalauthority.verifyyou.io';
}

export const apiURL = LOCAL
  ? 'http://localhost:' + localPort
  : remoteURL;

export const DIRECTORY_SERVICE_NAME = 'Verify You Authority';

export const TRUST_CHECK_SOFTWARE_NAME = 'Verify You Authority Service';
export const TRUST_CHECK_SOFTWARE_VERSION = '0.0.2';
export const TRUST_CHECK_COPYRIGHT_NOTICE = (<div>©2023 <a href="http://verifyyou.io" title="">Verify You</a></div>);
