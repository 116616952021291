import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { IconButton, Box } from '@material-ui/core';

import trustCheckLogo from '../../assets/images/trust-check-logo-circular.png';
import { DIRECTORY_SERVICE_NAME } from '../../Constants';

const HeaderLogo = () => {
  return (
    <Fragment>
      <div className={clsx('app-header-logo', {})}>
        <Box
          className="header-logo-wrapper"
          title="">
          <Link to="/DashboardDefault" className="header-logo-wrapper-link">
            <IconButton
              color="primary"
              size="medium"
              className="header-logo-wrapper-btn">
              <img
                className="app-header-logo-img"
                alt="Logo"
                src={ trustCheckLogo }
              />
            </IconButton>
          </Link>
          <Box className="header-logo-text">{ DIRECTORY_SERVICE_NAME }</Box>
        </Box>
      </div>
    </Fragment>
  );
};

export default HeaderLogo;
