export const LOCAL_STORAGE_AUTH_TOKEN_KEY = 'authToken';
export const LOCAL_STORAGE_CURRENT_DIRECTORY_ID_KEY = 'currentDirectoryId';
export const LOCAL_STORAGE_CURRENT_DIRECTORY_NAME_KEY = 'currentDirectoryName';
export const LOCAL_STORAGE_AUTHED_USER_NAME_KEY = 'authedUserName';
export const LOCAL_STORAGE_AUTHED_USER_PROFILE_IMAGE_URL_KEY = 'authedUserProfileImageURL';

export const getLocalStorageValue = (key) => {
  let value = localStorage.getItem(key);
  if (value === null) return undefined;
  return value;
}

export const setLocalStorageValue = (key, value) => {
  if (value !== undefined && value !== null) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
}
