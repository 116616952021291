import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { apiURL } from '../../Constants';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: apiURL,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getState().auth.token;
      if (token != null) {
        headers.set('authorization', token);
        headers.set('content-type', 'application/json');
      }
      return headers
    },
  }),
  tagTypes: ['ConsumerUser'],
  endpoints: builder => ({
    getMyDirectories: builder.query({
      query: () => ({
        url: '/v1/directory/get_mine',
        method: 'POST',
        body: JSON.stringify({}),
      })
    }),
    checkAndProgressConnection: builder.mutation({
      query: (consumerUserId) => ({
        url: '/v1/consumer_user/check_and_progress_connection',
        method: 'POST',
        body: JSON.stringify({consumer_user_id: consumerUserId}),
      }),
      invalidatesTags: ['ConsumerUser'],
    }),
    getUsersByDirectoryId: builder.query({
      query: (directoryId) => ({
        url: '/v1/consumer_user/get_by_directory_id',
        method: 'POST',
        body: JSON.stringify({directory_id: directoryId}),
      }),
      providesTags: ['ConsumerUser'],
    }),
    createConsumerUser: builder.mutation({
      query: ({ memberIdentifier, email, consumerUserId, directoryId }) => ({
        url: '/v1/consumer_user/create',
        method: 'POST',
        body: JSON.stringify({
          member_identifier: memberIdentifier,
          email: email,
          directory_id: directoryId,
         }),
      }),
      invalidatesTags: ['ConsumerUser'],
    }),
    getConsumerUser: builder.query({
      query: (consumer_user_id) => ({
        url: '/v1/consumer_user/get',
        method: 'POST',
        body: JSON.stringify({
          consumer_user_id: consumer_user_id,
        }),
      }),
      providesTags: ['ConsumerUser'],
    }),
    getSuperUser: builder.query({
      query: (super_user_id) => ({
        url: '/v1/super_user/get',
        method: 'POST',
        body: JSON.stringify({
          super_user_id: super_user_id,
        }),
      })
    }),
    editConsumerUser: builder.mutation({
      query: ({ consumerUserId, memberIdentifier, email, trustCheckUserInfo }) => ({
        url: '/v1/consumer_user/edit',
        method: 'POST',
        body: JSON.stringify({
          consumer_user_id: consumerUserId,
          member_identifier: memberIdentifier,
          email: email,
          trust_check_user_info: trustCheckUserInfo,
        }),
      }),
      invalidatesTags: ['ConsumerUser'],
    }),
    deleteConsumerUser: builder.mutation({
      query: ({ consumerUserId }) => ({
        url: '/v1/consumer_user/delete',
        method: 'POST',
        body: JSON.stringify({
          consumer_user_id: consumerUserId,
        }),
      }),
      invalidatesTags: ['ConsumerUser'],
    }),
    editSuperUser: builder.mutation({
      query: ({ superUserId, name, email, profileImageB64 }) => ({
        url: '/v1/super_user/edit',
        method: 'POST',
        body: JSON.stringify({
          super_user_id: superUserId,
          name: name,
          email: email,
          profile_image_b64: profileImageB64,
        }),
      })
    }),
    // Note: refreshAuth's results are caught in api/Auth to update user state
    refreshAuth: builder.query({
      query: () => ({
        url: '/v1/super_user/get',
        method: 'POST',
        body: JSON.stringify({}),
      })
    }),
    refreshConsumerUserFromTrustCheck: builder.mutation({
      query: (consumerUserId) => ({
        url: '/v1/consumer_user/refresh_from_trust_check',
        method: 'POST',
        body: JSON.stringify({
          consumer_user_id: consumerUserId,
        }),
      }),
      invalidatesTags: ['ConsumerUser'],
    }),
  })
})

export const {
  useGetUsersByDirectoryIdQuery,
  useGetMyDirectoriesQuery,
  useCheckAndProgressConnectionMutation,
  useCreateConsumerUserMutation,
  useLazyGetSuperUserQuery,
  useGetConsumerUserQuery,
  useEditSuperUserMutation,
  useEditConsumerUserMutation,
  useDeleteConsumerUserMutation,
  useLazyRefreshAuthQuery,
  useRefreshConsumerUserFromTrustCheckMutation,
} = apiSlice
