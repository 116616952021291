import { apiURL } from '../../Constants';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const authApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: apiURL,
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({email, password}) => ({
        url: '/v1/super_user/authenticate',
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            'email': email,
            'password': password,
        }),
      }),
    }),
  }),
})

export const { useLoginMutation, useRefreshQuery } = authApi
