import { createSlice } from '@reduxjs/toolkit'

const SearchSlice = createSlice({
  name: 'search',
  initialState: {value: ""},
  reducers: {
    updateSearch(state, action) {
        const value = action.payload
        state.value = value;
      },
  }
})

export const { updateSearch } = SearchSlice.actions

export default SearchSlice.reducer
