import React from 'react';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);

  return token != null ? children : <Redirect to='/login' />
}

export default PrivateRoute;
